import React from 'react';
import Helmet from 'react-helmet';
import Link from '../components/Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({pageContext}) => {
	const {title, cta} = pageContext;

	return (
		<React.Fragment>
			<Helmet title="404 - Genexa" />

			<div className="bg--gray-bg pt10 pb12">
				<div className="grid-container contained">
					<div className="row align--center mb4">
						<div className="col c6 c4--sm c2--lg">
							<img
								src="/images/404.png"
								alt="Not Found"
								className="db x"
							/>
						</div>
					</div>
					<div className="row align--center">
						<div className="col c11 c8--sm c6--md">
							<div className="tc">
								<div className="serif--lg serif--xxl--lg mb5">
									<BlockContent
										blocks={title}
										serializers={richText}
									/>
								</div>
								<Link {...cta} className="button--primary"  aria-label="Not Found" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
